import * as React from "react"
import { Link, graphql, PageProps } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex: React.FC<PageProps<GatsbyTypes.BlogIndexQuery>> = ({ data, location }) => {
  const siteTitle = data.site?.siteMetadata?.title || `nodata`;
  const posts = data.allContentfulBlogPost.nodes;

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="Index" />
        <Bio />
        <p>
          No blog posts found.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Index" />
      <Bio />
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.title || post.slug;
          const date = post.createdAt || 'nodata';
          const slug = post.slug || '';

          return (
            <li key={slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={`/blog/${slug}`} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{date}</small>
                </header>
                <section
                    dangerouslySetInnerHTML={{
                      __html: post.description?.childMarkdownRemark?.html || post.description?.childMarkdownRemark?.excerpt || 'nodata',
                    }}
                    itemProp="description">
                </section>
              </article>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex
export const pageQuery = graphql`
query BlogIndex {
  site {
    siteMetadata {
      title
    }
  }
  allContentfulBlogPost(sort: { fields: createdAt, order: DESC }) {
    nodes {
      title
      slug
      createdAt(formatString: "MMMM Do, YYYY")
      description {
        childMarkdownRemark {
          html
          excerpt
        }
      }
    }
  }
}
`